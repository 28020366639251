/* You can add global styles to this file, and also import other style files */

@import '/src/styles/general';
@import '/src/styles/color';

@import 'node_modules/@db-ui/core/sources/css/enterprise/db-ui-core-include.scss';

@font-face {
  font-family: "icons-24-outline";
  font-weight:100;
  font-style:normal;
  src: url("/src/assets/fonts/icons-24-outline-action.woff2") format("woff2"),
  url("/src/assets/fonts/icons-24-outline-action.woff") format("woff");
}

* {
    box-sizing: border-box;
}
html {
    overflow-x: auto;
}

html,
body {
    height: 100%;
    width: 100%
}

select {
  outline:0px;
}

input {
  caret-color: black !important;
}

.elm-input {
  --db-focus-outline-color: whitesmoke;
}

:root {
  --db-focus-outline-offset: 1px;
  --db-focus-outline-color: whitesmoke;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center
}

body, input {
  margin: 0;
  font-family: "DB Screen Sans", Helvetica, Arial, sans-serif, icons-24-outline;
}

.modal-backdrop {
  visibility: hidden !important;
}
.modal.in {
  background-color: rgba(0,0,0,0.5);
}

.full-screen-modal {
  width: 100%;
  height: 100%;  
}

.dim-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 1001;
}

// ********************* Arrow **************************
.arrow {
  svg {
    transform: rotate(90deg);
    transition: all ease-in-out 0.3s;
    z-index: 1;
  }
}

.arrow-up {
  svg {
    transform: rotate(-90deg);
  }
}

.arrow-div {
  align-items: center;
  display: flex;
}


// ********************* Progress bar **************************
.elm-progress {
    display: flex;
    height: 20px;
    justify-content: center;
    align-items: center;
  }

  
// ********************* Inputs **************************
.input-field {
    position: relative;
    width: 349px;
    
    // ng-multiselect-dropdown,
    input,
    select {
        font-size: 13px;
        height: 52px;
        margin: 0 0 4px;
        padding: 14px 16px 14px 17px;
        background-color: $background-color-light;
        border-bottom: none;
    }
    select {
        width: 349px;
    }
    textarea {
      background-color: $background-color-light;
    }
    input[type="radio"]{
      background-color: white;
      margin: 0 5px 0px 4px;
      padding: 14px 0px 14px 17px;
    }
}

.input-field .elm-radio + label{
  margin-left: 0;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    // -webkit-appearance: none;
    margin: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  // -webkit-appearance: none;
  margin: 0;  
}

// Position of the label inside the input, when the text is typed in
.elm-input[type='text'] + .elm-label,
.elm-input[type='text'] + .elm-label,
.elm-input[type='number'] + .elm-label,
.elm-input[type='datetime-local'] + .elm-label,
.elm-input[type='date'] + .elm-label,
.elm-input[type='time'] + .elm-label,
.elm-select + .elm-label
.elm-textarea + .elm-label {
    transform: translateY(-3.4rem) translateX(0.3rem);
    user-select: none;
}

.date-field {
    position: relative;

    &:not(.focused):in-range::-webkit-datetime-edit-year-field,
    &:not(.focused):in-range::-webkit-datetime-edit-month-field,
    &:not(.focused):in-range::-webkit-datetime-edit-day-field,
    &:not(.focused):in-range::-webkit-datetime-edit-hour-field,
    &:not(.focused):in-range::-webkit-datetime-edit-minute-field,
    &:not(.focused):in-range::-webkit-datetime-edit-text {
        color: transparent;
    }
}

.date-field.focused + .date-label {
    font-size: 0.75rem;
    color: $eerie-black-color;
    top: 18px;
    left: 4px;
}

.date-label {
    position: absolute;
    color: #afb1b5;
    font-size: 13px;
    top: 33px;
    left: 16px;
}

.invalid-input {
    border-bottom: 1px solid #ec0016 !important;
}

.input-error {
  display: block;
  font-size: 12px;
  margin-left: 16px;
  color: #ec0016;
}

.input-warn {
  display: block;
  font-size: 12px;
  margin-left: 16px;
  color: #FFD800;
}

.input-info {
  display: block;
  font-size: 12px;
  margin-left: 16px;
  color: #9e9e9e;
}

// **************** Modal windows **************************
.cdk-global-overlay-wrapper {
    display: flex;
}

.cdk-global-overlay-wrapper {
    background-color: rgba(0, 0, 0, 0.55);
}

.mat-dialog-container {
    background-color: $white-color;
    padding: 0 !important;
    margin-top: 10vh;
}

.modal-header-base {
    background: $background-color-light;
    padding: 30px;
    margin: 0;
    height: 132px;

    span {
        font-weight: normal;
    }

    h2 {
        margin-top: 16px;
    }

    .close-icon {
        position: absolute;
        top: 35px;
        right: 35px;
    }
}

.modal-header-sticky {
  @extend .modal-header-base;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  z-index: 10;
}

.modal-header {
  @extend .modal-header-base;
  position: relative;
}

.modal-main {
    max-height: calc(100vh - 294px);
    overflow-y: auto;
    padding: 30px;
}

.modal-footer-base {
  background-color: $white-color;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 15px;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70px;

  .button-navigation {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

}

.modal-footer {
  @extend .modal-footer-base;
  position: absolute;
}

.modal-footer-sticky {
  @extend .modal-footer-base;
  flex-shrink: 0;
  position: sticky;
  justify-content: space-between;
  z-index: 10;
}

.cmp-dialog {
    position: fixed;
    top: 0;
    left: 0;
    transform: unset;
    margin-top: 10vh;
    margin-bottom: 10vh;
    padding: 0;
    width: max-content;
    min-width: 400px;
    // max-height: 80vh;
    max-width: 80vw;

    hr {
        margin: 0.5rem 0;
    }
}

// ************************* Tables *****************************
.table-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0%;
    padding: 0px;
    background-color: $white-color;
    z-index: 10;
  }
  
  .list-table {
    width: 100%;
    min-width: 100%;
    border-spacing: 0;
    border-bottom: solid 1px $border-dark-color;
    line-height: normal;
    th {
      padding: 23px 20px 23px 5px;
      border-top: 1px solid $border-dark-color;
      border-bottom: 1px solid $border-dark-color;
      color: $mirage-black-color;
      font-weight: normal;
      line-height: 20px;
      vertical-align: middle;
      
      & > div {
        display: flex;
        align-items: center;
      }
      
      
      .sort-icons-block {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 10px;
        height: 100%;
        margin-left: 5px;
      }
  
      .sort-ascending, .sort-descending {
        width: 0;
        height: 0;
        margin: 2px;
      }
  
      .sort-ascending {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $border-light-color;
  
        &.active-sort {
          border-top: 5px solid $black-color;
        }
      }
  
      .sort-descending {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $border-light-color;
  
        &.active-sort {
          border-bottom: 5px solid $black-color;
        }
      }
    }
  
    tbody {
  
      tr:nth-child(odd) {
        background-color:  $background-grey-color;
      }
  
      tr:hover {
        background-color: rgba(0, 106, 150, 0.17);
      }
  
      .icon:nth-of-type(even) {
        margin-left: 10px;
      }
    }
  
    td {
      text-align: left;
      padding: 12px 16px 12px 10px;
      vertical-align: middle;
  
      .disruption-icon {
        color: #ff0000;
      }

      &.vertical-align {
        display: flex;
        align-items: center;
        justify-content: center;
      }

  
      &.align-center {
        text-align: center;
      }
  
      &:last-of-type {
        min-width: 70px;
      }

      a {
        text-decoration: underline;
      }
    }
  
    .menu {
      li {
        min-width: max-content;
      }
    }
  }

.bottom-controls {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 14px;
    
    .load-btn {
      position: absolute;
      cursor: pointer;
      display: block;
      width: 170px;
      height: 36px;
      left: calc(50% - 85px);
      top: 0;
      margin-top: 14px;
      padding: 8px 0;
      border-radius: 4px;
      font-size: 1rem;
      background-color: $white-color;
      border: solid 1px $eerie-black-color;
    }
  
    a {
      text-decoration: underline;
    }
  }

  .elm-link {
    cursor: pointer;
  }

  .pointer {
    cursor: pointer;
  }

  .text {
    cursor: text;
  }

  db-button :hover {
    cursor: pointer;
  }

// multiselect autocomplete component styles
/* General styling for selected items */
.ng-multiselect-dropdown-class > div > div:nth-child(1) > span > span.selected-item-container > span > a {
  color: #282d37 !important;
}

.ng-multiselect-dropdown-class > div > div:nth-child(1) > span > span.selected-item-container > span {
  border: 1px solid !important;
}

/* Common styling for span elements */
.ng-multiselect-dropdown-class > div > div:nth-child(1) > span > span > span {
  color: #282d37 !important;
  background-color: #f0f0f0 !important;
  font-family: "DB Screen Sans", Helvetica, Arial, sans-serif !important;
  font-size: 14px !important;
 // padding: 5px;
  border-radius: 5px !important;
  opacity: 0.8 !important;
  transition: all 0.3s ease !important;
  max-width: 350px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.ng-multiselect-dropdown-class > div > div:nth-child(1) > span > span > span {
  margin-top: 5px !important;
}

/* Padding for the first span inside selected-item-container */
.ng-multiselect-dropdown-class > div > div:nth-child(1) > span > span:nth-child(1) {
  padding: 0 5px 5px 5px !important;
  color: #afb1b5;
  font-size: 13px;
}

/* Container styling */
.ng-multiselect-dropdown-class > div > div:nth-child(1) > span {
  width: 100% !important;
  padding: 14px 30px 3px 5px !important;
  background-color: #f0f3f5 !important;
  position: relative !important;
  z-index: 1 !important;
}

/* Multi-select container styling */
.multi-select-container {
  width: 100%;
  min-height: 57px;
  background: none;
  margin: 16px 0px 0px 0px;
  
}

/* Label styling */
.elm-label {
  font-size: 12px;
  display: block;
  color: #646973 !important;
  margin: 0 0 -18px 5px;
  position: relative;
  z-index: 10;
}

/* Autocomplete styling */

.hide-icon {
  visibility: hidden;
  visibility: hidden;
}


.show-icon {
  visibility: visible;
  visibility: visible;
}


/* Checkbox styling */
.multiselect-item-checkbox input[type="checkbox"]:not(:checked) + div:before,
.multiselect-item-checkbox input[type="checkbox"]:not(:checked) + div:after,
.multiselect-item-checkbox input[type="checkbox"]:checked + div:before {
  box-sizing: content-box;
  content: "";
  color: #282d37 !important;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid #282d37 !important;
  text-align: center;
  transition: all 0.4s ease;
  background-color: #f0f0f0 !important;
}

.multiselect-item-checkbox input[type="checkbox"]:checked + div:before {
  background-color: #282d37 !important;
}

/* Adjust height of Selectbox */
.ng-multiselect-dropdown-class > div > div:nth-child(1) > span > span.selected-item-container > span > span {
  margin-top: 40px;
}

/* Active dropdown styling */
.ng-multiselect-dropdown-class > div > div:nth-child(1) > span > span.dropdown-multiselect--active > span:nth-child(1) {
  border: none;
}

.multiselect-dropdown[_ngcontent-ng-c1411523848]   .dropdown-btn[_ngcontent-ng-c1411523848]{
  border: none !important;
  min-height: 52px;
}
 
.input-padding-10 {
  padding: 0px 10px 0px 0px;
}

.mat-dialog-container {
  margin: 0;
  padding: 0;
}

.icon-block-autocomplete {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 15px;
  top: 30px;
  
  .icon {
      position: absolute;
  }
}