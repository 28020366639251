$black-color: #000;
$eerie-black-color: #131821;
$mirage-black-color: #141923;
$white-color: #fff;
$light-grey-color: #d7dce1;
$background-color-light: #f0f3f5;
$background-grey-color: #eef1f3;
$border-light-color: #c8cdd2;
$border-dark-color: #3c414b;
$traffic-light-green: #63A615;
$traffic-light-yellow: #FFD800;
$traffic-light-red: #EC0016;